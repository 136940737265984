// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import TransgressingImageContainer from 'components/Contentful/TransgressingImageContainer'
import Knowledge from 'components/Contentful/Knowledge'
import Brands from 'components/Contentful/Brands'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpe - Heizen mit erneuerbarer Energie"
        meta={[
          { name: 'description', content: 'Mit einer Wärmepumpe effizient und umweltfreundlich heizen. Wärmepumpen-Typen ✔ Speicher-Typen ✔ Marken ✔ Vorteile ✔ Hier mehr erfahren!' },
          { name: 'keywords', content: 'wärmepumpe, wärmepumpen' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='4JNQLvxKOvL8dRtxVdc4Vl-751WuFN29AY2WZEunP4wB6'
          id='passendes-system-mit-der-waermepumpe'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "751WuFN29AY2WZEunP4wB6",
    "updatedAt": "2023-07-25T13:58:32.392Z",
    "title": "Passendes System mit der Wärmepumpe",
    "size": "Mittel",
    "teaser": "Die Wärmepumpe ist zentraler Bestandteil in einem Gesamtsystem. Wenn sie mit Wärmeverteilung, Warmwassererzeugung und Gebäudeisolation harmoniert, schafft sie optimalen Komfort.",
    "image": {
        "description": "Vitocal 250 aussenaufgestellte Wärmepumpe",
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/ibGCekaID8nvf9kFboCSf/f94a105100e210c306a397f42ec84488/2306_-_EInleitung_und_Bild_Titelbild.jpg"
    },
    "links": [
        {
            "type": "link",
            "id": "52LtUnYpq3pbMjNzaKpmjM",
            "updatedAt": "2019-05-03T15:34:26.732Z",
            "label": "Kontaktieren Sie uns",
            "url": "/kontakt"
        }
    ],
    "anchorName": "passendes-system-mit-der-waermepumpe"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='4JNQLvxKOvL8dRtxVdc4Vl-4PTC3aPAn8AATQgXc8CtXF'
          id=''
        >
          <TransgressingImageContainer
            {...{
    "type": "transgressingImageContainer",
    "id": "4PTC3aPAn8AATQgXc8CtXF",
    "updatedAt": "2021-02-11T08:50:46.314Z",
    "title": "Unsere Wärmepumpen Typen",
    "transgressingImages": [
        {
            "type": "transgressingImage",
            "id": "41xD2klAvEvA26ef0lq2BB",
            "updatedAt": "2021-02-11T07:50:17.138Z",
            "title": "Luft/Wasser Wärmepumpe",
            "image": {
                "description": "Luft/Wasser Wärmepumpe Aussenaufstellung",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2FRf1aLlnRRJ6fpG0MGUml/19c891b7f71933c1325b719e2c5c2188/Icon_Heizungsmacher_LW_aussen.svg"
            },
            "text": "<p><strong>Aussenaufstellung</strong></p>\n<p>Die aussenaufgestellte Luft/Wasser Wärmepumpe bezieht die Energie aus der Luft. Die meistverbaute Variante bei Sanierungen.</p>\n",
            "link": {
                "type": "link",
                "id": "5KcOEtCxA213hzQO5P8YbJ",
                "updatedAt": "2021-02-11T11:17:43.089Z",
                "label": "Details",
                "page": {
                    "slug": "waermepumpen/luft-wasser-aussenaufstellung"
                }
            }
        },
        {
            "type": "transgressingImage",
            "id": "5VgOgjbmPGvP8A8gbMHXeY",
            "updatedAt": "2021-02-11T07:50:10.641Z",
            "title": "Luft/Wasser Wärmepumpe",
            "image": {
                "description": "Luft Wasser Wärmepumpe Innenaufstellung",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5SYw6TvQ1ETPC2WYB8fxNp/3bb33c6d250df7f421e2c09e64206cae/Icon_Heizungsmacher_LW_innen.svg"
            },
            "text": "<p><strong>Innenaufstellung</strong></p>\n<p>Die innenaufgestellte Luft/Wasser Wärmepumpe benötigt einen Lufteinlass und Auslass, meistens via Lichtschacht. Schalloptimiert für enge Bebauungen.</p>\n",
            "link": {
                "type": "link",
                "id": "16ElSHyPXSLomLyNrsSXxJ",
                "updatedAt": "2021-02-11T11:17:58.667Z",
                "label": "Details",
                "page": {
                    "slug": "waermepumpen/luft-wasser-innenaufstellung"
                }
            }
        },
        {
            "type": "transgressingImage",
            "id": "41lCYYotfFYrJzEJ1hmL1U",
            "updatedAt": "2021-02-11T07:50:03.465Z",
            "title": "Sole/Wasser Wärmepumpe",
            "image": {
                "description": "Icon Solar Wasser Wärmepumpe",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2N3Q4PRJPapY3ipvXMxeLg/db348a822564d2f844dd14635a6fd190/Icon_Heizungsmacher_SW.svg"
            },
            "text": "<p><strong>Erdsonde</strong></p>\n<p>Die Sole/Wasser Wärmepumpe bezieht die Energie aus dem Erdreich. Dank konstanten Sole Temperaturen werden höchste Effizienzwerte erreicht.</p>\n",
            "link": {
                "type": "link",
                "id": "6iq83xugt5t4ck5icLQIAL",
                "updatedAt": "2021-02-11T11:18:11.576Z",
                "label": "Details",
                "page": {
                    "slug": "waermepumpen/sole-wasser-erdsonde"
                }
            }
        }
    ]
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='4JNQLvxKOvL8dRtxVdc4Vl-6kHJHtM43CUqGWCemiuIkY'
          id='System'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "6kHJHtM43CUqGWCemiuIkY",
    "updatedAt": "2023-07-25T12:49:14.199Z",
    "image": {
        "description": "Einfamilienhaus mit Sicht auf die Haustechnik im Speziellen die Wärmepumpe und der Kombispeicher",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/27lpeHDFoAwwSaiOEUsGaA/a540d57baf926a41ea05153ad333f916/hardik-pandya-294216_highlight.png"
    },
    "details": [
        {
            "type": "knowledgeDetail",
            "id": "WhBuXcoUmIeMKY6gUQEuK",
            "updatedAt": "2018-04-02T08:48:13.570Z",
            "description": "<p><strong>Wärmeverteilung</strong>\nOb Radiatoren oder Bodenheizung – wir legen die Wärmepumpe so aus, wie es zu den bestehenden Gebäudeinstallationen passt.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "2lndIaRVRe0Ue0OM6GoIki",
            "updatedAt": "2018-04-03T12:40:39.598Z",
            "description": "<p><strong>Warmwasserspeicher</strong>\nDamit Sie immer genauso warm duschen können wie Sie möchten, berechnen wir den Warmwasserspeicher exakt nach Ihren Bedürfnissen.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "5ouSgYT9726Miyooe0gAQu",
            "updatedAt": "2018-04-02T08:49:30.926Z",
            "description": "<p><strong>Wärmepumpe</strong>\nWir sorgen dafür, dass das Herz Ihrer neuen Heizung genau richtig dimensioniert ist. So geniessen Sie immer angenehme Raumtemperaturen, ohne zu viel zu bezahlen.</p>\n"
        },
        {
            "type": "knowledgeDetail",
            "id": "3OoyGAPGYMkuWeGSss4ycu",
            "updatedAt": "2022-05-10T07:42:31.730Z",
            "description": "<p><strong>Kombi-Lösung: <a href=\"https://www.heizungsmacher.ch/waermepumpe-photovoltaik/\">Wärmepumpe &#x26; Photovoltaik</a></strong>\nAls Heizungs- und Solarprofis besitzen wir das Know-how für die einwandfreie Einbindung von Wärmepumpen in Photovoltaik-Anlagen in Ein- und Zweifamilienhäusern.</p>\n"
        }
    ],
    "link": {
        "type": "link",
        "id": "7p7eaG5GRI8A1fElOkNxIb",
        "updatedAt": "2022-06-28T08:23:33.929Z",
        "label": "Online Heizungs-Check",
        "url": "https://www.heizungsmacher.ch/konfigurator"
    },
    "imageInBackground": true,
    "anchorName": "System",
    "description": ""
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='4JNQLvxKOvL8dRtxVdc4Vl-1btRizhSiwSYmuKguOmGEy'
          id='unsere-marken'
        >
          <Brands
            {...{
    "type": "brands",
    "id": "1btRizhSiwSYmuKguOmGEy",
    "updatedAt": "2022-07-15T12:49:20.482Z",
    "title": "Unsere Marken",
    "description": "<p>Wir setzen ausschliesslich auf bewährte Produkte und Systeme von anerkannten Markenherstellern. Fortlaufend überprüfen wir den Markt und bieten markenunabhängig die derzeit besten Produkte an.</p>\n",
    "logos": [
        {
            "description": "Logo Alpha Innotec Wärmepumpen",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1MKYoPax52UCqcueUuS4ou/f7a0a47d5c1f00c3d911f62d3fddc424/ait_Logo-01.svg"
        },
        {
            "description": "Logo NIBE Wärmepumpen",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1kYauulSew7223PSLVoaSY/bfd4374415edb5976a88a83a4d438796/NIBE_Logo-01.svg"
        },
        {
            "description": "Logo Stiebel Eltron Wärmepumpen",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1yTD2XeRRS20CyiKo2oAQS/a306b364720ee15fcc1a41c0bb5e34f8/Stiebel_Eltron_Logo-01.svg"
        },
        {
            "description": "Logo Viessmann Wärmepumpen",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/3iUiUcSpCU6smMegWAeecC/74d9b69f17778c3d4b2095df7f0824c1/Viessmann_Logo-01.svg"
        },
        {
            "description": "",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1EANKmnIm3MhB4IgheIqSm/82effc92b3c458fba8951e292dfe412a/Page_1.svg"
        },
        {
            "description": "",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/e8Su7TKUXg9ugoIjpyYnq/e0ebf5722659476638f02762fc0fce0c/Page_1__1_.svg"
        }
    ],
    "anchorName": "unsere-marken"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='4JNQLvxKOvL8dRtxVdc4Vl-7b0X9rPrhZZEExrHGFXhfT'
          id=''
        >
          <TransgressingImageContainer
            {...{
    "type": "transgressingImageContainer",
    "id": "7b0X9rPrhZZEExrHGFXhfT",
    "updatedAt": "2021-02-11T08:50:54.609Z",
    "title": "Unsere Speicher Typen",
    "transgressingImages": [
        {
            "type": "transgressingImage",
            "id": "6Z6FpiHCakFywxJ6mQ8MYg",
            "updatedAt": "2022-10-05T08:39:54.696Z",
            "title": "Kompakt-Speicher",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/47J5BT8sntksxXDl4uI3Jc/1b0916e9c6919d8ebb77d92c403a951f/Icon_Speicher_copy-03.svg"
            },
            "text": "<p>Zwei in einem, die kompakte Lösung für kleine Räume. Im Kompaktspeicher sind Boiler und Pufferspeicher übereinander angeordnet - für mehr Nutzfläche.</p>\n",
            "link": {
                "type": "link",
                "id": "7GFczh4H52qcZIWELwjV9g",
                "updatedAt": "2021-02-09T16:23:08.217Z",
                "label": "Details",
                "page": {
                    "slug": "waermepumpen/kompakt-speicher"
                }
            }
        },
        {
            "type": "transgressingImage",
            "id": "3UFHLxZOP4IVViWqFPSFUU",
            "updatedAt": "2022-10-05T08:40:03.127Z",
            "title": "Effizienz-Speicher",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5CQJx7olRh0p9eXA0sTBXP/0b60f76c4cc0aa5d4ca9284db30cbdd6/Icon_Speicher_copy-01.svg"
            },
            "text": "<p>Die klassische Variante unter den Speichertypen. Hier kommen der Pufferspeicher als auch der Boiler getrennt zum Einsatz. Dies ist eine bewährte Variante für den einfachen Einbau in bestehende Systeme.</p>\n",
            "link": {
                "type": "link",
                "id": "7cEAe2Vlg72hmCkFAzxxzj",
                "updatedAt": "2021-02-09T16:29:45.710Z",
                "label": "Details",
                "page": {
                    "slug": "waermepumpen/effizienz-speicher"
                }
            }
        },
        {
            "type": "transgressingImage",
            "id": "5gisVC68xCAVjt725ArLdO",
            "updatedAt": "2022-10-05T08:40:11.447Z",
            "title": "Kombi-Speicher",
            "image": {
                "description": "Grafik Kombispeicher Puffer Trinkwarmwasser",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3STLPnm6mnWnAXKBlFaPJb/288b3c386e78fde1fe9a837196e1e89c/Icon_Speicher_copy-02.svg"
            },
            "text": "<p>Der Kombi-Speicher überzeugt mit der intelligenten Einbindung von Wärmeerzeuger und hygienischer Trinkwasser-Aufbereitung im Durchlaufprinzip. Auch ideal im Einsatz mit einem zweiten Wärmeerzeuger.</p>\n",
            "link": {
                "type": "link",
                "id": "2H26VtID6chnfGxs6n69aq",
                "updatedAt": "2021-02-09T16:34:33.915Z",
                "label": "Details",
                "page": {
                    "slug": "waermepumpen/kombi-speicher"
                }
            }
        }
    ]
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
